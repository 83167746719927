<template>
  <div style="height: 100%;">
    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle;height:60px;">
        <div class="form-group" style="vertical-align: middle;">
          <div>
            <label
              for="txtContractNumber"
              class="col-sm-1 control-label"
              style="padding-top: 10px; text-align: right"
              >商品名称</label
            >
          </div>
          <div class="col-sm-2" style="vertical-align: middle">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              v-model.trim="searchpname"
            />
          </div>
          <!-- <label
            for="txtSampleName"
            class="col-sm-1 control-label"
            style="padding-top: 10px; text-align: right"
            >商品条码</label
          >
          <div class="col-sm-2" style="margin: 0; padding: 0">
            <input
              type="text"
              class="form-control1"
              id="txtSampleName"
              name="txtSampleName"
              
              v-model.trim="searchpsn"
              
            />
          </div> -->
          <div class="col-sm-5 " style="padding-top: 2px">
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="getProductList"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
          </div>
          
        </div>
        <!-- </form> -->
      </div>
      <!-- </div> -->
    </div>

    <div class="grid-form1" style="height: 678px">
      <!-- <h4>问题类别维护</h4> -->
      <div
        class="tab-content"
        style="height: 605px; margin-top: 5px; padding: 0"
      >

        <div class="order_item" v-if="productList.length > 0">
            <div class="order_item_7" style="border-bottom: 1px solid #eee;text-align:center;">商品名称</div>
            <div class="order_item_1" style="text-align:center;">商品规格</div>
            <div class="order_item_2">总耗损量</div>
            <div class="order_item_3">操作</div>
        </div>
        <div v-for="(s, index) in currentpagelist"
            :key="index">
            <div class="order_item">
                <div class="order_item_7" style="border-bottom: 1px solid #eee;">{{ s.pname }}</div>
                <div class="order_item_1" style="text-align:center;">{{ s.pguige }}</div>
                <div class="order_item_2">{{ s.num }}</div>
                <div class="order_item_3" v-if="!s.collapse"><a @click="getFClassCal(index,s.pid,s.sid)">展开</a></div>
                <div class="order_item_3" v-else><a @click="closeCList(index)">收起</a></div>
            </div>
            <div v-if="s.collapse">
                <div
                    
                    v-for="(item, index) in s.detaillist"
                    :key="index"
                    
                >
                    <div class="order_item">
                        <div class="order_item_4">&nbsp;</div>
                        <div class="order_item_5">{{item.wastagedate}}</div>
                        <div class="order_item_5">{{item.opruser}}新增耗损，数量：{{item.num}}</div>
                        <div class="order_item_6">&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-if="noResultFlag" class="noresult">没有找到符合查询条件的耗损核销记录！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
        v-if="!noResultFlag"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";


export default {
  name: "wastagelist",
  components: {
  },
  data() {
    return {
        collapseB:false,
      productList: [],
      orderListC:[],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpsn: "",
      searchpname: "",
      noResultFlag:false,
      show: false,
      isshow: false,
      isshowupdate:false,
      win_height: "",
      currentSID: 0,
      currentSInfo: [],
    };
  },
  methods: {
    async getFClassCal(pi,pid,sid){

        const res = await this.$get(
        this.GLOBAL.serverAddress + "/getWastageListByPid/",{
            pid:pid,
            sid:sid
        });
        console.log(res);

        if (res.status === 200) {

            // this.orderListF = res.data;
            this.currentpagelist[pi].collapse=true
            this.currentpagelist[pi].detaillist=res.data;
            // Vue.set(this.currentpagelist,pi,res.data)

            // this.collapseB=true;
            // if (this.orderListF.length > 0) {
            //     this.shownoresult = false;
            //     for(var i = 0; i < this.orderListF.length;i++) {
            //         this.orderListC.push([])
            //     }
            //     this.collapseB=true;
            // } else {
            //     this.shownoresult = true;
            // }
        }
    },
    closeCList(pi){
        this.currentpagelist[pi].collapse=false
    },
    clearSearch() {
      this.searchpname = "";
      this.searchpsn = "";
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },
    getProductList() {
      //   if (days) {
      axios({
        method: "get",
        params: {
          spname: this.searchpname,
          spsn: this.searchpsn,
        },
        url: this.GLOBAL.serverAddress + "/getWastageList/",
      }).then(
        (response) => {
          this.productList = response.data;
          
          if(this.productList.length > 0){
              for(var i = 0; i < this.productList.length;i++) {
                this.orderListC.push([])
            }
              this.noResultFlag=false
          }else{
              this.noResultFlag=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );
      //   }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.productList.length;
    },
    pagecount() {
      return Math.ceil(this.productList.length / 15);
    },
    pages() {
      const pages = [];
      this.productList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0204|')
    this.getProductList();
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="less">
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
.order_item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #9c9c9c;
    line-height:30px;
    .order_item_1{
        flex: 2;
        border-bottom: 1px solid #eee;
    }
    .order_item_2{
        flex: 1;
        border-bottom: 1px solid #eee;
        
    }
    .order_item_3{
        flex: 1;
        border-bottom: 1px solid #eee;
    }
    .order_item_4{
        flex: 4;
    }
    .order_item_5{
        flex: 2;
        border-bottom: 1px dashed #eee;
        font-style: italic;
        font-size:0.9em;
    }
    .order_item_6{
        flex: 1;
    }
    .order_item_7{
        flex: 4;
        padding-left: 50px;
        text-align: left;
    }
}

</style>